<template>
  <a class="nav-item nav-link" :class="{ active, disabled }">
    <div v-if="showNumber" class="wizard-step-icon">{{ item.id }}</div>
    <div class="wizard-step-text">
      <div class="wizard-step-text-name">{{ item.label }}</div>
      <div class="wizard-step-text-details">{{ item.description }}</div>
    </div>
  </a>
</template>

<script>
export default {
  name: 'WizardHeaderStep',

  props: {
    active: {
      type: Boolean,
      required: false,
      default: false
    },

    item: {
      type: Object,
      required: true,
      default: () => ({})
    },

    showNumber: {
      type: Boolean,
      required: false,
      default: true
    },

    disabled: {
      type: Boolean,
      required: false,
      default: false
    }
  }
}
</script>
